.cedcf nav.site-desktop-menu > ul > li > a {
    padding: 0 40px;
}

.cedcf nav.site-desktop-menu > ul > li a {
    text-transform: none;
}

@media (max-width: 1200px) {
    .cedcf nav.site-desktop-menu > ul > li > a {
        padding: 0 30px;
    }
}

.cedcf .site-header .btn-mobile-menu i {
    border: 1px solid #ffffff;
    color: #ffffff;
    background: #00418a;
}

.cedcf .site-header .btn-mobile-menu {
    margin: 56px 0;
}

@media (max-width: 992px) {
    .cedcf .site-header-2 .main-bar .header-buttons {
        margin-top: 57px;
    }
}

.padding-top-bottom-15 {
    padding-bottom: 15px;
}

#section-gmap {
    border-bottom: 4px solid #ccc;
}

#content.no-padding-bottom {
    padding-bottom: 0;
}

form.inscription {
    line-height: 1.3em;
}

form.inscription input[type="checkbox"],
form.inscription input[type="radio"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    padding: 0;
    margin: 0 10px 0 0;
    display: inline-block;
    vertical-align: middle;
}

form.inscription .subtotal_txt,
form.inscription .total_to_pay_txt {
    display: inline;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent !important;
    width: auto;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    color: #00418a;
}

.wpcf7-form select {
    border: 1px solid #D1D3D4;
    color: #5F5D5D;
    background: #f4f6f7;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 10px 20px 10px 4px;
    outline: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

form.inscription [class*="col-"] {
    margin-bottom: 15px;
}

form.inscription section {
    padding-bottom: 40px;
}

form.inscription input.registration_choice,
form.inscription #events_choice_wrapper input,
form.inscription input#accept {
    float: left;
    margin-top: 2px;
}

form.inscription label.registration_choice,
form.inscription #events_choice_wrapper label,
form.inscription label[for="accept"] {
    font-weight: normal;
    display: block;
    margin-left: 30px;
}

form.inscription label.registration_choice strong,
form.inscription #events_choice_wrapper label strong {
    font-weight: bold;
}

form.inscription #events_choice_wrapper {
    margin-top: 15px;
}

form.inscription #events_choice_wrapper .event_participation_type {
	display: none;
}

form.inscription #events_choice_wrapper .participation_type_choice .choice_title {
	margin-left: 30px;
}

form.inscription #sub_total_wrapper input {
    width: 90% !important;
}

#slider .events_slider_event_title {
    display: block;
    font-size: 28px;
    line-height: 30px;
    white-space: normal;
    width: 650px;
    max-width: 90% !important;
}

#slider .events_slider_event_bg {
    max-width: 95% !important;
    max-height: 80% !important;
}

.post-entry-more {
    display: none;
}

.post-entry-more-show-btn {
    text-decoration: none !important;
}

.post-entry .btn {
    margin: 0 0 20px 0;
}

#login-form input {
    height: auto;
}

.wpcf7-form select {
    margin-bottom: 5px;
    padding: 12px 20px 12px 16px !important;
}

.wpcf7-form input[type="text"],
.wpcf7-form input[type="file"],
.wpcf7-form input[type="date"],
.wpcf7-form input[type="datetime"],
.wpcf7-form input[type="email"],
.wpcf7-form input[type="password"],
.wpcf7-form textarea {
    line-height: 22px;
}

form.inscription input[name="country_other"],
form.affiliate input[name="country_other"],
form.affiliate input[name="private_country_other"] {
    display: none;
}

.admin-modal-dialog .container {
    width: auto;
}

.admin-edit-block-open {
    font-size: 14px;
    line-height: 14px;
}

.admin-edit-block-open .fa {
    color: #ff8100;
    font-size: inherit;
}

.modal-backdrop.in {
    opacity: 0.75;
}

.site-header-2 .main-bar #baseline div {
    margin-bottom: 4px;
}

.site-header-2 .main-bar #baseline div p,
.cedcf a.btn-primary p,
.cedcf a.btn p,
.cedcf .subheader h1 p,
.quick_newsletter .newsletter-info h3 p,
.quick_newsletter .newsletter-info div p,
footer.footer-2 .footer-contact ul li p,
.sub-footer div.inline p {
    margin: 0 !important;
    padding: 0 !important;
    display: inline !important;
}

.blog-grid .item .post-media {
    min-height: 140px;
}

label.disabled {
    opacity: 0.5;
}

footer.footer-2 .footer-contact ul {
    max-width: 550px;
}

footer.footer-2 .footer-contact ul li.blk {
    display: block;
}

footer.footer-2 .footer-contact ul a,
footer.footer-2 .sub-footer a {
    color: #666666;
    text-decoration: none;
}

footer.footer-2 .footer-contact ul a:hover,
footer.footer-2 .sub-footer a:hover {
    color: #00418a;
    text-decoration: none;
}

footer.footer-2 .sub-footer span.separator {
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block;
}

#cookies_box{
	position: fixed;
	z-index: 9999;
	bottom: 0;
	width: 100%;
	padding: 30px 0;
	background-color: #00418a;
	border-top: 2px solid #ffffff;
	color: #ffffff;
}
@media only screen and (min-width: 768px) {
	#cookies_box .cookies_box_inner {
		display: flex;
		align-items: center;
	}
}
@media only screen and (max-width: 767px) {
	#cookies_box .cookie_bt_wrapper{
		margin-top: 10px;
	}
}
#cookies_box #cookie_accept{
	background-color: transparent;
	border: 2px solid #ffffff;
	color: #ffffff;
}
#cookies_box #cookie_accept:hover{
	background-color: #ffffff;
	color: #00418a;
}
#cookies_box #cookie_more{
	display: inline-block;
	margin-left: 15px;
	color: #ffffff;
	transition: .5s;
}
#cookies_box #cookie_more:hover{
	color: #7192bb;
}


.cm-privacy-email-wrapper{
	padding-top: 10px;
}
label[for="cm-privacy-email"] {
	color: white;
	font-weight: normal;
}
label[for="cm-privacy-email"] a {
	color: white;
	font-weight: bold;
}
label[for="cm-privacy-email"] a:hover {
	color: white;
	text-decoration: underline;
}
