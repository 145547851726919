@import url("/css/bootstrap.css");
/* css by bootstrap */
@import url("/css/import-font.css");
/* used to load font from external source like Google font */
@import url("/fonts/font-awesome/css/font-awesome.css");
/* used to load font awesome icons */
@import url("/rs-plugin/css/settings.css");
/* css file for Revolution Slider plugin */
@import url("/css/owl.carousel.css");
/* css for jQuery plugin "Owl Carousel" */
@import url("/css/owl.theme.css");
/* css for jQuery plugin "Owl Carousel" */
@import url("/css/animate.css");
/* css for animating template elements */
@import url("/css/magnific-popup.css");
/* css for jQuery plugin "Magnific Popup" */
@import url("/css/bg-image.css");
/* css for section background image */
@import url("/css/rev-settings.css");
/* custom css file for Revolution Slider plugin, you can modify slider typography from this file */