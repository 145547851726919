@charset "utf-8";
/*
 * LESS VARIABLES
 */
/* Colors
----------------------------------------------------------*/
/* Body
----------------------------------------------------------*/
/* Typography
----------------------------------------------------------*/
/*------------------------------
BODY
------------------------------ */
body {
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 22px;
    margin: 0;
    padding: 0;
    color: #666666;
    background: #fff;
    text-shadow: none !important;
}

#wrapper {
    overflow: hidden;
}

a,
a:focus,
a:active {
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-weight: bold;
    color: #333333;
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    margin-top: 0px;
}

/*------------------------------
HEADER
------------------------------ */
.site-header-1 .main-bar {
    float: left;
    width: 100%;
}

.stuck {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 499;
}

header.site-header {
    position: relative;
    background-color: #fff;
    border-top: 5px solid #29b6f6;
}

header.site-header-1 {
    min-height: 115px;
}

#logo {
    margin-top: 25px;
}

/*	site-desktop-menu	*/
nav.site-desktop-menu {
    width: auto;
    float: left;
    margin-top: 0px;
    margin-left: 20px;
}

nav.site-desktop-menu > ul {
    float: right;
    position: relative;
    z-index: 100;
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 0px;
}

nav.site-desktop-menu > ul > li {
    width: auto;
    margin: 0px;
    display: inline-block;
    vertical-align: bottom;
    float: left;
    line-height: 65px;
    position: relative;
}

nav.site-desktop-menu > ul > li > a {
    text-transform: capitalize;
    line-height: 114px;
    padding: 0 15px;
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
    font-size: 17px;
    color: #555555;
    -webkit-transition: .5s;
    transition: .5s;
}

.site-header-2 nav.site-desktop-menu > ul > li > a {
    color: #ffffff;
}

nav.site-desktop-menu > ul > li > a:hover {
    color: #28b7f9;
}

nav.site-desktop-menu > ul > li > ul {
    position: absolute;
    top: 110px;
    left: 18px;
    background-color: #042433;
    width: 260px;
    border-top-width: 3px;
    border-top-style: solid;
    border-color: #28b7f9;
    display: none;
    z-index: 100;
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: left;
}

nav.site-desktop-menu > ul > li:last-child > ul {
    right: 0;
    left: inherit;
}

nav.site-desktop-menu > ul > li > ul li {
    line-height: 40px;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #0b2b3a;
}

nav.site-desktop-menu > ul > li > ul a {
    color: #fff;
    text-transform: capitalize;
    padding: 0px 20px;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    -webkit-transition: .5s;
    transition: .5s;
    display: block;
    font-size: 13px;
}

nav.site-desktop-menu > ul > li > ul a:hover {
    background: #28b6f6;
}

nav.site-desktop-menu > ul li {
    position: relative;
}

nav.site-desktop-menu > ul > li ul ul {
    width: 260px;
    right: -260px;
    top: -3px;
    border: 0px solid #f00;
    position: absolute;
    background-color: #042433;
    border-top-width: 3px;
    border-top-style: solid;
    border-color: #28b7f9;
    display: none;
    list-style: none;
    margin: 0px;
    padding: 0px;
}

/* style sticky menu 1 */
.site-header-1 .stuck {
    border-top: 5px solid #29b6f6;
}

.site-header-1 .stuck #logo {
    margin-top: 9px;
}

.site-header-1 .stuck nav.site-desktop-menu > ul > li > a {
    line-height: 80px;
}

.site-header-1 .stuck .header-buttons {
    margin-top: 28px;
}

.site-header-1 .stuck nav.site-desktop-menu > ul > li > ul {
    top: 80px;
}

/* site-desktop-menu-2 */
.site-header-2 .sub-header {
}

.site-header-2 .main-bar {
    padding-bottom: 0;
}

.site-header-2 .main-bar #logo {
    margin-top: 15px;
    margin-bottom: 5px;
}

.site-header-2 .main-bar #baseline {
    margin-top: 64px;
    margin-left: 30px;
}

.site-header-2 .main-bar #baseline span {
    display: block;
    margin-bottom: 4px;
}

.site-header-2 .main-bar #baseline span.large-title {
    font-size: 2em;
}

.site-header-2 .main-bar .header-buttons {
    margin-top: 57px;
}

nav.site-desktop-menu-2 {
    margin-left: 0px;
}

nav.site-desktop-menu-2 > ul {
    width: 100%;
    text-align: center;
    margin: 0px;
    padding: 0px;
}

nav.site-desktop-menu-2 > ul > li > a {
    line-height: 53px;
}

nav.site-desktop-menu-2 > ul > li {
    margin-left: 0px;
    margin: 0px;
    display: inline-block;
    vertical-align: bottom;
    float: left;
}

nav.site-desktop-menu-2 > ul > li:first-child > a {
    padding-left: 0px;
}

nav.site-desktop-menu-2 > ul > li:first-child > ul {
    left: 0px;
}

nav.site-desktop-menu-2 > ul > li:first-child {
    margin-left: 0px;
}

nav.site-desktop-menu-2 > ul > li > ul {
    top: 53px;
}

/* sticky header menu 2 */
.site-header-2 .stuck .sub-header {
    border-top: 0px solid #29b6f6;
    float: none;
    width: 100%;
    position: fixed;
    background: #ffffff;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.site-header-2 .stuck .main-bar {
    display: none;
}

/* site-desktop-menu-3 */
header.site-header-3 #logo {
    position: absolute;
    top: 28px;
    margin-top: 0px;
}

header.site-header-3 nav.site-desktop-menu {
    float: right;
    margin-left: 0px;
    margin-top: 24px;
}

header.site-header-3 nav.site-desktop-menu > ul > li:last-child {
    margin-right: 0px;
}

header.site-header-3 nav.site-desktop-menu > ul > li:last-child a {
    padding-right: 0px;
}

header.site-header-3 nav.site-desktop-menu > ul > li > a {
    line-height: 56px;
}

header.site-header-3 nav.site-desktop-menu > ul > li > ul {
    top: 56px;
}

header.site-header-3 .header-buttons {
    margin-top: 15px;
    width: 100%;
}

header.site-header-3 .header-buttons > * {
    float: right;
}

header.site-header-3 .header-buttons > *:first-child {
    margin-left: 28px;
}

/* Sticky site header 3 */
header.site-header-3 .stuck {
    border-top: 5px solid #29b6f6;
}

header.site-header-3 .stuck .header-buttons {
    display: none;
}

header.site-header-3 .stuck #logo {
    position: static;
    top: inherit;
    margin-top: 15px;
    float: left;
}

header.site-header-3 .stuck nav.site-desktop-menu {
    margin-top: 0px;
}

header.site-header-3 .stuck nav.site-desktop-menu > ul > li > a {
    line-height: 80px;
}

header.site-header-3 .stuck nav.site-desktop-menu > ul > li > ul {
    top: 80px;
}

/*	site-mobile-menu	*/
.site-header .btn-mobile-menu {
    display: inline-block;
    float: right;
    width: 40px;
    margin: 44px 0;
    cursor: pointer;
}

.site-header .btn-mobile-menu i {
    width: 100%;
    line-height: 40px;
    text-align: center;
    border: 1px solid #e6e6e6;
    color: #b1b5b8;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.site-header .btn-mobile-menu:hover i,
.site-header .btn-mobile-menu.clicked i {
    background-color: #29b6f6;
    color: #fff;
    border: 1px solid #29b6f6;
}

nav.site-mobile-menu {
    width: 100%;
    float: left;
    margin-top: -57px;
    position: relative;
    border: 0px solid #f00;
}

nav.site-mobile-menu > ul {
    position: absolute;
    top: 54px;
    width: 100%;
    z-index: 50000;
    border-top-color: #29b6f6;
    border-top-width: 3px;
    border-top-style: solid;
    background-color: #303335;
    display: none;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

nav.site-mobile-menu > ul li {
    position: relative;
    -webkit-transition: background-color 0.4s ease 0s, background-color 0.4s ease 0s;
    transition: background-color 0.4s ease 0s, background-color 0.4s ease 0s;
}

nav.site-mobile-menu > ul > li {
    float: left;
    width: 100%;
    border: 0px solid rgba(255, 255, 255, 0.05);
    border-bottom-width: 1px;
}

nav.site-mobile-menu > ul > li a {
    line-height: 40px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 20px;
    color: #fff;
    width: 100%;
    display: block;
    text-decoration: none;
    -webkit-transition: .5s;
    transition: .5s;
}

nav.site-mobile-menu > ul > li a:hover {
    background: #29b6f6;
}

nav.site-mobile-menu > ul > li ul {
    width: 100%;
    float: left;
    background-color: #1f2224;
    display: none;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

nav.site-mobile-menu > ul > li ul li {
    width: 100%;
    float: left;
    border: 0px solid rgba(255, 255, 255, 0.05);
    border-bottom-width: 1px;
}

nav.site-mobile-menu > ul li i.fa {
    color: #fff;
    cursor: pointer;
    line-height: 40px;
    padding: 0 20px;
    position: absolute;
    right: 0;
    top: 0;
}

nav.site-mobile-menu li > ul {
    display: none;
}

/*	gray-line	*/
.gray-line {
    width: 100%;
    float: left;
    background-color: #f2f2f2;
    height: 3px;
    z-index: 99;
    position: relative;
    margin-top: -4px;
}

.gray-line3 {
    width: 100%;
    float: left;
    background-color: #f2f2f2;
    height: 3px;
    z-index: 99;
    position: relative;
    margin-top: -1px;
}

.gray-line-2 {
    height: 1px;
    margin-top: -1px;
    background-color: #f2f2f2;
}

.gray-line-3 {
    top: 166px;
}

/* Header Contact
	------------------------------ */
.header-contact ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.header-contact ul li {
    font-size: 15px;
    color: #999999;
    display: inline-block;
    margin-left: 25px;
}

.header-contact ul li .fa {
    font-size: 15px;
    color: #555555;
    margin-right: 2px;
}

.header-contact ul li:first-child {
    margin-left: 0px;
}

.header-contact ul li.border-line {
    color: #cccccc;
    margin-left: 20px;
}

.header-buttons {
    margin-top: 48px;
}

.header-buttons > * {
    display: inline-block;
    margin-left: 28px;
}

.header-buttons > *:first-child {
    margin-left: 0px;
}

.header-buttons .fa {
    font-size: 15px;
    color: #555555;
    -webkit-transition: .3s;
    transition: .3s;
}

.header-buttons .btn .fa {
    color: #fff;
}

.header-buttons a:hover .fa {
    color: #29b6f6;
}

.search-button a {
    outline: none;
}

/* Modal Search Box
	----------------------- */
/*
.search-button .modal {
    overflow: hidden;
}

.modal {
    background: rgba(4, 36, 51, 0.9);
    z-index: 500000;
    overflow: hidden;
}

.modal-content {
    background: transparent;
    -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.5);
    border: 0px solid rgba(0, 0, 0, 0.2);
}

.modal-dialog {
    margin: 0 auto;
    text-align: center;
    position: absolute;
    width: 100%;
}

.modal-body {
    padding: 0px;
}

.modal-body .search-form {
    width: 555px;
    display: inline-block;
    position: relative;
    margin-top: -60px;
}

.modal-body .search-form input[type="search"] {
    border-bottom: 3px solid #27424f;
    border-top: 0px solid #27424f;
    border-right: 0px solid #27424f;
    border-left: 0px solid #27424f;
    font-size: 36px;
    line-height: 36px;
    font-weight: 300;
    width: 100%;
    background: transparent;
    color: #fff;
    padding: 10px 0px;
}

.modal-body .search-form input[type="search"]:focus {
    outline: none;
}

.modal-body .search-form .search-submit {
    position: absolute;
    right: 0;
    top: 13px;
    border: none;
    background: transparent;
}

.modal-body .search-form .search-submit .fa {
    color: #a6abaf;
    font-size: 30px;
}

#myModal .close {
    background: #29b6f6 none repeat scroll 0 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    font-weight: normal;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    position: absolute;
    right: 25px;
    top: 25px;
    width: 30px;
    z-index: 100000;
    font-family: arial;
}
*/
/* Top Cart
	--------------------------- */
.cart-button {
    position: relative;
}

.header-buttons .cart-button .mini-cart-counter {
    position: absolute;
    display: inline-block;
    color: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    min-width: 18px;
    padding: 0 5px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    top: -10px;
    right: -9px;
    font-size: 11px;
    font-weight: 700;
    background-color: #29b6f6;
    font-family: arial;
}

.cart-button .top_cart_list_product {
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: #fff;
    border: 0px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    font-size: 14px;
    left: inherit;
    list-style: outside none none;
    margin: 0px 0 0;
    min-width: 200px;
    padding: 10px 20px 20px 20px;
    position: absolute;
    right: 0;
    top: 64px;
    z-index: 1000;
    border-top: 3px solid #29b6f6;
    margin-right: -20px;
}

.stuck .cart-button .top_cart_list_product {
    top: 51px;
}

.cart-button .top_cart_list_product .cart_list.product_list_widget {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.cart_list.product_list_widget .mini_cart_item {
    display: block;
    width: 270px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
    float: left;
    position: relative;
}

.cart_list.product_list_widget .img-thumb {
    display: inline-block;
    float: left;
    margin-right: 10px;
    width: 90px;
}

.cart_list.product_list_widget .product-detail {
    padding-left: 100px;
    font-size: 13px;
}

.cart_list.product_list_widget .product-detail .remove {
    position: absolute;
    top: 50%;
    right: 0px;
    margin-top: -10px;
}

.cart_list.product_list_widget .product-detail .remove .fa {
    font-size: 10px;
    color: #999999;
}

.cart_list.product_list_widget .product-detail a {
    color: #333;
    text-transform: uppercase;
    -webkit-transition: .3s;
    transition: .3s;
    text-decoration: none;
    outline: none;
}

.cart_list.product_list_widget .product-detail a:hover {
    color: #29b6f6;
}

.cart_list.product_list_widget .product-detail .quantity {
    display: block;
    color: #808080;
    font-size: 13px;
}

.top_cart_list_product .total {
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
    text-align: right;
    color: #555555;
}

.top_cart_list_product .buttons {
    margin: 0;
    padding-top: 10px;
    text-align: left;
}

/* Offcanvas Menu
	--------------------------- */
.compact-menu-canvas {
    position: fixed;
    right: -260px;
    top: 0px;
    height: 100%;
    width: 260px;
    z-index: 10009;
    background: #042433;
    border-left: 1px solid rgba(255, 255, 255, 0.08);
}

.compact-menu-canvas h3 {
    margin: 0px;
    padding: 20px 0px;
    font-size: 15px;
    line-height: 40px;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    color: #cccccc;
}

.compact-menu-canvas nav {
    width: 100%;
    float: left;
    position: relative;
    overflow: auto;
}

.compact-menu-canvas nav ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.compact-menu-canvas nav ul li {
    line-height: 40px;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #0c2b39;
}

.compact-menu-canvas nav ul li a {
    color: #fff;
    text-transform: capitalize;
    padding: 0px 20px;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    -webkit-transition: .5s;
    transition: .5s;
    display: block;
    font-size: 13px;
}

.compact-menu-canvas nav ul li a:hover {
    background: #29b6f6;
}

#btn-close-canvasmenu {
    display: block;
    background: #29b6f6 none repeat scroll 0 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    position: absolute;
    left: 25px;
    top: 30px;
    width: 20px;
    z-index: 100000;
    text-align: center;
    cursor: pointer;
}

.header-buttons #btn-close-canvasmenu i.fa {
    color: #fff;
}

.navright-button .isLeft i.fa-bars {
    color: #29b6f6;
}

.site-header-2 .main-bar .cart-button .top_cart_list_product {
    top: 62px;
}

.site-header-2 .search-bar {
    width: 235px;
    height: 35px;
    float: right;
    top: 9px;
    position: relative;
    padding: 0;
    margin: 0;
}

.site-header-2 .search-bar .search-box input[type="search"] {
    outline: none;
    color: #888;
    width: 100%;
    border: 1px solid #5c799a;
    height: 35px;
    line-height: 25px;
    background: none;
    padding: 0 45px 0 12px;
    -webkit-appearance: none;
    -webkit-border-radius: 20px;
    border-radius: 20px;
}

.site-header-2 .search-bar .search-box input[type="search"]:focus,
.site-header-2 .search-bar .search-box input[type="search"]:active {
    border: 1px solid #29b6f6;
}

.site-header-2 .search-bar .search-submit {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 35px;
    width: 45px;
    font-size: 20px;
    color: #5c799a;
    -webkit-transition: .5s;
    transition: .5s;
}

.site-header-2 .search-bar .search-submit:hover {
    color: #29b6f6;
}

/* #subheader
--------------------------- */
.subheader h1 {
    color: #333333;
    font-size: 30px;
    float: left;
    padding-right: 40px;
    text-transform: none;
    display: block;
    margin: 0px;
}

.subheader .breadcrumbs {
    font-size: 13px;
    color: #555555;
    float: right;
    list-style: none;
    margin: 10px 0px 0px 0px;
    padding: 0px;
}

.subheader .breadcrumbs li {
    display: inline-block;
}

.subheader .breadcrumbs a {
    text-decoration: none;
    outline: none;
}

.subheader .breadcrumbs li + li:before {
    content: "/";
    /* \f101 , \f105, font-family: Fontawesome; */
    margin: 0 10px;
    font-size: 12px;
}

#sub-header-2 {
    background: #f7f7f7 none repeat scroll 0 0;
    padding: 30px 0;
}

#sub-header-2 .breadcrumbs {
    margin: 5px 0 0;
}

#sub-header-3 {
    background-image: url('/images/bg/sub-header-bg.jpg');
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

#sub-header-3 .breadcrumbs-outer {
    position: absolute;
    right: 15px;
    bottom: -60px;
    background-color: #25B9F9;
}

#sub-header-3 input[type="text"] {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0px;
    transition-style: linear;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    color: #fafafa;
}

#sub-header-3 h1 {
    color: #fff;
    font-weight: normal;
}

#sub-header-3 .breadcrumbs {
    width: auto;
    text-transform: capitalize;
    float: right;
    padding: 1px 30px 0px;
    height: 30px;
    line-height: 30px;
    font-size: 11px;
    color: #fff;
    margin-top: 0px;
}

#sub-header-3 .breadcrumbs li + li:before {
    content: "\f101";
    /* \f101 , \f105, font-family: Fontawesome; */
    font-family: Fontawesome;
    margin: 0 10px;
    font-size: 12px;
}

#sub-header-3 .breadcrumbs li a {
    color: #fff;
}

#sub-header-3 .breadcrumbs li a:hover {
    text-decoration: underline;
}

/* Breadcrumbs 4 */
#sub-header-4 .subheader-top {
    background-color: #f8f8f8;
    padding: 45px 0px;
}

#sub-header-4 .subheader-bottom .breadcrumbs {
    float: none;
    margin: 0px;
    padding: 13px 15px 12px 0;
}

#sub-header-4 .subheader-bottom .breadcrumbs li a {
    color: #555;
    font-size: 13px;
}

#sub-header-4 .subheader-bottom .breadcrumbs li a:hover {
    color: #25B9F9;
}

#sub-header-4 .breadcrumbs-line {
    background: #ebebeb none repeat scroll 0 0;
    height: 1px;
    width: 100%;
}

section.content h5.title {
    background: #eaedf2 none repeat scroll 0 0;
    margin: 0 0 15px;
    padding: 12px;
    font-size: 14px;
}

code {
    background: #666 none repeat scroll 0 0;
    border: 0 none;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    color: #fff;
    display: block;
    font-family: consolas, monaco, "andale mono", monospace;
    font-weight: normal;
    line-height: 1.3;
    margin: 8px 0;
    padding: 12px;
    white-space: pre-wrap;
}

.spacing-45 {
    padding-top: 45px;
}

h3.headline {
    font-size: 20px;
    text-transform: none;
}

a {
    outline: medium none;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

/*------------------------------
ELEMENTS
------------------------------ */
/* Spinner */
.images-preloader {
    position: fixed;
    z-index: 100001;
    background-color: #303335;
    width: 100%;
    height: 100%;
}

.images-preloader .spinner {
    position: absolute;
    margin-left: -35px;
    margin-top: -9px;
    left: 50%;
    top: 50%;
    width: 70px;
    text-align: center;
}

.images-preloader .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #29b6f6;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.images-preloader .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.images-preloader .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    40% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

/* #Custom Css
================================================== */
.spacing-30 {
    height: 30px;
    clear: both;
}

.spacing-15 {
    height: 15px;
    clear: both;
}

.spacing-10 {
    height: 10px;
    clear: both;
}

.box-title {
    font-size: 20px;
}

abbr {
    border-bottom: 1px dotted;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.inline {
    display: inline-block;
}

.no-padding,
#content.no-padding {
    padding: 0px 0px 0px 0px;
}

.no-padding-bottom {
    padding-bottom: 0px;
}

.no-padding-top {
    padding-top: 0px;
}

.padding-top-bottom-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.no-margin {
    margin: 0px 0px 0px 0px !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-170 {
    margin-top: 170px;
}

.padding-bottom-0 {
    padding-bottom: 0px !important;
}

.bg-grey {
    background-color: #f8f8f8;
}

.no-boder {
    border-top: 0px solid transparent;
}

section,
#content {
    padding: 60px 0 60px 0;
    background: #fff;
}

.section-elements {
    padding-top: 0px;
}

.small-border {
    clear: both;
    width: 80px;
    height: 3px;
    background: #999999;
    margin: 20px auto 20px auto;
}

.tiny-border {
    clear: both;
    width: 40px;
    height: 2px;
    background: #999999;
    margin: 0px 0 20px 0;
}

.text-center .tiny-border {
    margin: 0px auto 30px auto;
}

.tiny-border-light {
    clear: both;
    width: 40px;
    height: 2px;
    background: rgba(255, 255, 255, 0.5);
    margin: 0px 0 20px 0;
}

.divider-single {
    clear: both;
    height: 30px;
}

.spacer-single {
    clear: both;
    height: 60px;
}

.list-style-1 {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.list-style-1 li {
    position: relative;
    padding-left: 20px;
    color: #666666;
    margin-bottom: 10px;
}

.list-style-1 li:before {
    position: absolute;
    top: 0px;
    left: 0px;
    font-family: 'FontAwesome';
    display: inline-block;
    font-size: 13px;
    content: "\f058";
    color: #aaaaaa;
}

.list-style-1 li:last-child {
    margin-bottom: 0px;
}

/* #Social Group hover color
================================================== */
.social-icons a {
    background: #083347;
}

.social-icons a.facebook:hover {
    background-color: #02549F;
}

.social-icons a.vk:hover {
    background-color: #4C75A3;
}

.social-icons a.vimeo:hover {
    background-color: #1AB7EA;
}

.social-icons a.tumblr:hover {
    background-color: #35465D;
}

.social-icons a.twitter:hover {
    background-color: #00ACED;
}

.social-icons a.behance:hover {
    background-color: #1769FF;
}

.social-icons a.instagram:hover {
    background-color: #DDCCBA;
}

.social-icons a.pinterest:hover {
    background-color: #CD2129;
}

.social-icons a.flickr:hover {
    background-color: #FF0084;
}

.social-icons a.dribbble:hover {
    background-color: #EA4C89;
}

.social-icons a.google:hover {
    background-color: #D11516;
}

.social-icons a.youtube:hover {
    background-color: #E12A27;
}

.social-icons a.linkedin:hover {
    background-color: #006699;
}

.social-icons a.skype:hover {
    background-color: #00A7E5;
}

/* Social Share Group */
.social-share a {
    color: #888888;
    display: inline-block;
    padding: 0px;
    margin: 0px 8px 0px 0px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 2px solid #e6e6e6;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.social-share a .fa {
    font-size: 15px;
}

.social-share a:hover {
    color: #fff;
    border-color: #29b6f6;
    background-color: #29b6f6;
}

/* #button Group
================================================== */
.btn {
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    padding: 10px 30px 10px 30px;
    display: inline-block;
    text-decoration: none;
    border: none;
    font-size: 13px;
    font-weight: bold;
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    line-height: 21px;
    text-align: center;
}

.btn.width {
    min-width: 170px;
    padding: 15px 30px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    font-size: 15px;
    margin-bottom: 20px;
}

.btn i {
    margin-right: 7px;
}

.btn.green {
    background-color: #4caf50;
}

.btn.orange {
    background-color: #ff9800;
}

.btn.red {
    background-color: #f44336;
}

.btn.grey {
    background-color: #e6e6e6;
    color: #555555;
}

.btn.grey:hover {
    color: #fff;
}

.btn.black {
    background-color: #555555;
}

.btn.yellow {
    background-color: #ffeb3b;
    color: #333333;
}

.btn.yellow:hover {
    color: #fff;
}

.btn-primary {
    background: #29b6f6;
    color: #fff;
}

.btn-secondary {
    background: #00B863;
    color: #fff;
}

.btn-third {
    background: #555555;
    color: #fff;
}

.btn-border {
    border: solid 2px #2c3e50;
    color: #2c3e50;
    background: #ffffff;
}

.btn-border-light {
    background: transparent;
    border: solid 2px #ffffff;
}

.btn:hover {
    background-color: #2C3E50;
}

.btn-secondary:hover {
    color: #ffffff;
}

.btn-third:hover {
    color: #ffffff;
}

.btn-border:hover {
    background: #a1b1bc;
    color: #333333;
}

.btn-border-light:hover {
    background: #2C3E50;
    color: #222222;
    border-color: #2C3E50;
}

.btn-border-grey {
    border: 2px solid #ccc;
    color: #999;
}

.btn-border-grey:hover {
    color: #29b6f6;
    border-color: #29b6f6;
    background-color: transparent;
}

.mleft {
    margin-right: 15px;
}

.btn-big {
    padding: 15px 40px 15px 40px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    font-size: 15px;
}

.btn-small {
    padding: 10px 25px 10px 25px;
}

.btn-radius.width,
.btn-radius {
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
}

.no-radius.width,
.no-radius {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
}

/* #About Boxes
================================================== */
.feature {
    padding-left: 68px;
    position: relative;
    margin: 26px 0px 34px;
}

.feature i {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    border-width: 1px;
    border-style: solid;
    font-size: 24px;
    text-align: center;
    color: #29b6f6;
    border-color: #29b6f6;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.feature h3 {
    font-size: 15px;
    color: #555555;
    text-transform: none;
}

.feature:hover i {
    background: #29b6f6;
    color: #fff;
}

/* #Project Boxes
================================================== */
.latest-projects .latest-projects-intro {
    background: #29b6f6;
    width: 25%;
    float: left;
    padding: 30px 30px 0px 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.latest-projects .latest-projects-intro h2 {
    color: #fff;
}

.latest-projects .latest-projects-intro p {
    color: #f2f2f2;
}

.latest-projects .latest-projects-wrapper {
    float: left;
    width: 75%;
}

.latest-projects-items .item {
    position: relative;
}

.project-details {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.8);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 100;
    display: none;
    text-align: center;
    overflow: hidden;
}

.project-details p {
    text-align: center;
    margin: 0px;
    position: absolute;
    left: 0px;
    top: -20px;
    width: 100%;
}

.project-details p.folio-cate {
    top: auto;
    bottom: -20px;
    color: #fff;
}

.project-details p.folio-cate i {
    font-size: 15px;
    color: #29b6f6;
    margin-right: 5px;
}

.project-details p a {
    color: #fff;
    text-decoration: none;
    outline: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.project-details p a:hover {
    color: #29b6f6;
}

.project-details .folio-buttons {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0px;
    text-align: center;
    margin-top: -20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.project-details .folio-buttons i {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: #29b6f6;
    margin: 0px 2px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 15px;
}

.project-details .folio-buttons a:hover i {
    color: #fff;
    border-color: #fff;
}

.latest-projects-wrapper .owl-theme .owl-controls {
    margin: 0px;
}

.latest-projects-wrapper .owl-theme .owl-controls .owl-buttons div {
    position: absolute;
    top: 50%;
    color: #fff;
    font-size: 20px;
    border: none;
    width: 32px;
    height: 32px;
    line-height: 32px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    margin: 0px;
    margin-top: -16px !important;
    background: #29b6f6;
    filter: alpha(opacity=70);
    /*IE7 fix*/
    opacity: 0.7;
}

.latest-projects-wrapper .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0px;
}

.latest-projects-wrapper .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: 0px;
}

.latest-projects-wrapper .owl-theme .owl-controls .owl-buttons div:hover {
    filter: alpha(opacity=100);
    /*IE7 fix*/
    opacity: 1;
    text-decoration: none;
}

/* Styling Next and Prev buttons */
.owl-theme .owl-controls .owl-buttons div {
    color: #999999;
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
    margin: 0px;
    padding: 0px 0px;
    width: 22px;
    height: 22px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #999999;
    background: transparent;
    filter: alpha(opacity=100);
    /*IE7 fix*/
    opacity: 1;
    margin: 0px 3px;
}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover {
    filter: alpha(opacity=100);
    /*IE7 fix*/
    opacity: 1;
    text-decoration: none;
    color: #ffffff;
    background: #29b6f6;
    border-color: #29b6f6;
}

/* Styling Pagination*/
.owl-theme .owl-controls .owl-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
    display: block;
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    filter: alpha(opacity=50);
    /*IE7 fix*/
    opacity: 0.5;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    background: #29b6f6;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    filter: alpha(opacity=100);
    /*IE7 fix*/
    opacity: 1;
}

/* Blog post slider */
.compact-buttons {
    display: inline-block;
    margin-left: 20px;
}

.compact-buttons div {
    color: #999999;
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #999999;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.compact-buttons div:hover {
    color: #ffffff;
    background: #29b6f6;
    border-color: #29b6f6;
}

.compact-buttons .compact-prev {
    margin-right: 2px;
}

.latest-blog-posts h2,
.testimonials-slider-wrapper h2 {
    display: inline-block;
}

.latest-blog-post-img {
    position: relative;
    float: left;
    max-width: 200px;
}

.latest-blog-post-img img {
    width: 100%;
}

.latest-blog-post-img .latest-blog-post-date {
    color: #29b6f6;
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px 0px 8px 0px;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.latest-blog-post-img .latest-blog-post-date span {
    display: block;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 13px;
    margin-top: 3px;
}

.latest-blog-post-details {
    padding-left: 220px;
}

.latest-blog-post-details p.title {
    font-size: 18px;
    color: #555555;
    font-weight: 600;
    line-height: 24px;
}

.latest-blog-post-details p.title a {
    color: #555555;
    text-decoration: none;
    outline: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.latest-blog-post-details p.title a:hover {
    color: #29b6f6;
}

.latest-blog-post-details .latest-blog-post-data {
    margin-top: 24px;
}

.latest-blog-post-details .latest-blog-post-data span {
    display: inline-block;
}

.latest-blog-post-details .latest-blog-post-data span.tags i {
    color: #29b6f6;
    margin-right: 4px;
}

.latest-blog-post-details .latest-blog-post-data span.tags a {
    font-size: 13px;
    color: #555555;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-decoration: none;
    outline: none;
}

.latest-blog-post-details .latest-blog-post-data span.tags a:hover {
    color: #29b6f6;
}

.latest-blog-post-details .latest-blog-post-data span.btn-read {
    float: left;
}

.latest-blog-post-details .latest-blog-post-data span.btn-read a {
    font-size: 13px;
    color: #555555;
    text-decoration: none;
    outline: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.latest-blog-post-details .latest-blog-post-data span.btn-read a:hover {
    color: #29b6f6;
}

.latest-blog-post-details .latest-blog-post-data span.btn-read a i {
    color: #29b6f6;
    margin-right: 2px;
}

.latest-news-items-3 .item,
.testimonials-slider-3 .item {
    margin: 0px 15px;
}

.compact-buttons.text-center {
    display: block;
    margin: 0px;
}

.item.margin_15 {
    margin: 0px 15px 15px 15px;
}

/* Blog List */
.blog-list article {
    margin-bottom: 50px;
}

.blog-list article:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.blog-list .post-media {
    float: left;
    width: 370px;
}

.blog-list .post-content {
    padding-left: 400px;
}

.post-content .post-title {
    font-size: 20px;
}

.post-content .post-title a {
    color: #333333;
    text-transform: none;
    font-weight: bold;
    text-decoration: none;
    outline: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.post-content .post-title a:hover {
    color: #29b6f6;
}

.post-media {
    position: relative;
}

.post-date {
    background: rgba(0, 0, 0, 0.5);
    padding: 8px 0px 8px 0px;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.post-date .date-day {
    color: #29b6f6;
    font-size: 24px;
}

.post-date .date-month {
    display: block;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 13px;
}

.post-metadata {
    margin: 25px 0px 15px;
}

.post-metadata span {
    display: inline-block;
    margin-right: 20px;
}

.post-metadata span:last-child {
    margin-right: 0px;
}

.post-metadata i {
    color: #29b6f6;
    font-size: 13px;
    margin-right: 2px;
}

.post-metadata a {
    color: #555555;
    text-decoration: none;
    outline: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.post-metadata a:hover {
    color: #29b6f6;
}

.post-entry {
    color: #666666;
}

.post-entry .btn {
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
}

.post-entry .btn:hover {
    color: #fff;
    background-color: #2c3e50;
    border-color: #2c3e50;
}

/* Blog Grid */
.blog-grid .item {
    margin-bottom: 30px;
}

.blog-grid .item .post-media {
    margin-bottom: 22px;
}

.blog-grid .item .post-metadata {
    margin: 15px 0px;
}

/* Single Blog */
.blog-single .post-title {
    margin-top: 40px;
}

.blog-single .post-metadata {
    margin-bottom: 30px;
    margin-top: 25px;
}

.blog-single p {
    margin-bottom: 20px;
}

.footer-entry {
    margin: 50px 0px 30px 0px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ecf0f1;
    border-top: 1px solid #ecf0f1;
}

.single-tags {
    margin-top: 10px;
}

.single-tags .fa {
    color: #29b6f6;
    margin-right: 4px;
}

.single-tags a {
    color: #555555;
    text-decoration: none;
    outline: none;
    display: inline-block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.single-tags a:hover {
    color: #29b6f6;
}

.hr {
    height: 1px;
    clear: both;
    width: 100%;
    background-color: #ecf0f1;
    margin-bottom: 30px;
}

blockquote {
    font-family: 'Droid Serif', serif;
    font-style: italic;
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-left: 30px;
    border-left: 5px solid #29b6f6;
}

blockquote cite,
blockquote small {
    color: #333333;
    display: block;
    font-size: 13px;
    font-family: 'Raleway', serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 10px;
}

/* Post Nav Link */
.nav-links a {
    color: #999999;
    font-size: 15px;
    text-decoration: none;
    outline: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.nav-links a:hover .post-title {
    color: #29b6f6;
}

.nav-links .post-title {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    margin-top: 8px;
}

.nav-links .nav-previous {
    width: 50%;
    float: left;
}

.nav-links .nav-previous span {
    display: block;
}

.nav-links .nav-next {
    width: 50%;
    float: right;
    text-align: right;
}

.nav-links .nav-next span {
    display: block;
}

/* Comment Form */
.comments-area {
    margin-top: 80px;
}

.comments-area .comment-form-author,
.comments-area .comment-form-email,
.comments-area .comment-form-url {
    width: 31.7554321%;
    float: left;
    margin-right: 20px;
}

.comments-area .comment-form-author input,
.comments-area .comment-form-email input,
.comments-area .comment-form-url input {
    width: 100%;
    background: #f9fafb;
    color: #a1b1bc;
    border: 1px solid #eceef0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    line-height: 20px;
    padding: 14px 20px;
}

.comments-area .comment-form-author input:focus,
.comments-area .comment-form-email input:focus,
.comments-area .comment-form-url input:focus,
.comments-area .comment-form-author input:active,
.comments-area .comment-form-email input:active,
.comments-area .comment-form-url input:active {
    outline: none;
    background: #fff;
}

.comments-area .comment-form-url {
    margin-right: 0px;
}

.comments-area .comment-form-comment {
    clear: both;
}

.comments-area .comment-form-comment textarea {
    width: 100%;
    height: 180px;
    background: #f9fafb;
    color: #a1b1bc;
    border: 1px solid #eceef0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    line-height: 20px;
    padding: 14px 20px;
}

.comments-area .comment-form-comment textarea:focus,
.comments-area .comment-form-comment textarea:active {
    outline: none;
    background: #fff;
}

.comments-area input[type=submit] {
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    padding: 15px 25px 15px 25px;
    display: inline-block;
    text-decoration: none;
    border: none;
    font-size: 13px;
    font-weight: bold;
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #29b6f6;
}

.comments-area input[type=submit]:hover {
    background-color: #2c3e50;
}

/* Comment template */
.comment-list {
    padding: 0px;
    list-style: none;
}

.comment-list article,
.comment-list .pingback,
.comment-list .trackback {
    border-top: 1px solid #d1d1d1;
    padding: 1.75em 0;
}

.comment-list .children {
    list-style: outside none none;
    margin: 0;
}

.comment-list .children > li {
    padding-left: 0.875em;
}

.comment-author {
    color: #1a1a1a;
    margin-bottom: 0.4375em;
}

.comment-author .avatar {
    float: left;
    height: 50px;
    margin-right: 0.875em;
    position: relative;
    width: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.comment-author .fn a {
    color: #555555;
    font-size: 15px;
    text-decoration: none;
}

.comment-author .fn a:hover {
    color: #29b6f6;
}

.bypostauthor > article .fn:after {
    content: "";
    left: 3px;
    position: relative;
    top: 5px;
}

.comment-metadata,
.pingback .edit-link {
    color: #686868;
    font-family: Montserrat, "Helvetica Neue", sans-serif;
    font-size: 11px;
    line-height: 1.61538;
}

.comment-metadata {
    margin-bottom: 15px;
}

.comment-metadata a,
.pingback .comment-edit-link {
    color: #686868;
}

.comment-metadata a:hover,
.comment-metadata a:focus,
.pingback .comment-edit-link:hover,
.pingback .comment-edit-link:focus {
    color: #007acc;
}

.comment-metadata .edit-link,
.pingback .edit-link {
    display: inline-block;
}

.comment-metadata .edit-link::before,
.pingback .edit-link:before {
    content: "/";
    display: inline-block;
    opacity: 0.7;
    padding: 0 0.538462em;
}

.comment-content {
    margin-left: 60px;
}

.comment-content ul,
.comment-content ol {
    margin: 0 0 1.5em 1.25em;
}

.comment-content li > ul,
.comment-content li > ol {
    margin-bottom: 0;
}

.comment-body {
    position: relative;
}

.comment-body .reply {
    position: absolute;
    top: 0px;
    right: 0px;
}

.comment-reply-link {
    border: 1px solid #d1d1d1;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #666;
    text-transform: uppercase;
    display: inline-block;
    font-family: Montserrat, "Helvetica Neue", sans-serif;
    font-size: 12px;
    line-height: 1;
    margin-top: 2.15385em;
    padding: 8px 8px 8px 25px;
    position: relative;
    text-decoration: none;
}

.comment-reply-link:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f112";
    position: absolute;
    left: 8px;
    top: 6px;
}

.comment-reply-link:hover,
.comment-reply-link:focus {
    border-color: currentcolor;
    color: #29b6f6;
    outline: 0 none;
    text-decoration: none;
}

/* Pagination */
.pagination-ourter {
    width: 100%;
    clear: both;
}

.pagination {
    padding-left: 0;
    margin: 20px 0;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    display: inline-block;
}

.pagination > li > a,
.pagination > li > span {
    padding: 0px;
    margin-left: 7px;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: block;
    color: #555555;
    text-decoration: none;
    background-color: #e6e6e6;
    border: 0px solid #ddd;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    -webkit-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.pagination > li > span.current,
.pagination > li.active > span,
.pagination > li > a:hover {
    background-color: #00418a !important;
    color: #ffffff;
}

/* Main Siderbar */
.main-sidebar .widget {
    margin-bottom: 50px;
}

.main-sidebar .widget:before,
.main-sidebar .widget:after {
    content: " ";
    display: table;
}

.main-sidebar .widget:after {
    clear: both;
}

.main-sidebar .widget ul {
    list-style: none;
    padding-left: 0px;
}

.main-sidebar .widget ul ul {
    padding-left: 20px;
}

.main-sidebar .widget ul li {
    position: relative;
    padding-left: 13px;
    margin-bottom: 5px;
}

.main-sidebar .widget ul li:before {
    font-family: 'FontAwesome';
    display: inline-block;
    font-size: 13px;
    content: "\f105";
    color: #555555;
    position: absolute;
    left: 0px;
    top: 0px;
}

.main-sidebar .widget ul li a {
    color: #555555;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-decoration: none;
    outline: none;
}

.main-sidebar .widget ul li a:hover {
    color: #29b6f6;
}

.tagcloud a {
    display: inline-block;
    margin: 0 7px 7px 0;
    padding: 5px 10px;
    text-shadow: none;
    font-size: 13px;
    float: left;
    border: 1px solid #ecf0f1;
    color: #888;
    text-decoration: none;
    outline: none;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.tagcloud a:hover {
    color: #fff;
    background-color: #29b6f6;
    border-color: #29b6f6;
}

.widget .search-form {
    position: relative;
}

.widget .search-form input[type=search] {
    color: #888888;
    border: 1px solid #ddd;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 20px;
}

.widget .search-form .search-submit {
    position: absolute;
    top: 1px;
    right: 0px;
    width: 40px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    display: block;
    font-size: 18px;
    border: none;
    background: transparent;
    color: #888888;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.widget .search-form .search-submit:hover {
    color: #29b6f6;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
    color: #888888;
    border: 1px solid #ddd;
}

/* Testimonials */
.testi-boxes blockquote {
    font-family: 'Droid Serif', serif;
    font-style: italic;
    font-size: 13px;
    line-height: 22px;
    color: #666666;
    border-left: none;
    padding: 0px;
    padding-left: 40px;
    position: relative;
    margin: 0px;
    border: none;
    margin-bottom: 20px;
}

.testi-boxes blockquote:before {
    font-family: 'Droid Serif', serif;
    content: "“";
    color: #29b6f6;
    font-size: 60px;
    font-weight: 700;
    display: inline-block;
    font-style: normal;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 15px 3px 0px 0px;
}

.testi-boxes .testi-info {
    padding-left: 35px;
}

.testi-boxes .testi-info img {
    float: left;
}

.testi-boxes .testi-info .testi-details {
    padding-left: 65px;
    color: #555555;
    font-weight: normal;
}

.testi-boxes .testi-info .testi-details span {
    display: block;
    color: #333333;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
}

.testimonials-slider-2 .testi-boxes .testi-info {
    padding-left: 0px;
    display: inline-block;
    margin: 5px 0px 25px;
}

.testimonials-slider-2 .testi-boxes .testi-info .testi-details {
    text-align: left;
}

/* Call To Action */
.cal-to-action .btn {
    color: #fff;
}

.cal-to-action span {
    color: #fff;
    font-size: 18px;
    margin-right: 60px;
}

.cal-to-action span b {
    font-size: 30px;
    font-weight: normal;
}

/* #Progress bars
================================================== */
.about-history h5 {
    margin: 30px 0px 20px;
}

.pro-bar-container {
    position: relative;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 100%;
    height: 10px;
    background: #d9d9d9;
}

.pro-bar-margin {
    margin-bottom: 25px;
}

.skills-name {
    position: relative;
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
    color: #555555;
}

.skills-name span {
    color: #111;
    padding-left: 5px;
}

/* Style default */
.pro-bar {
    position: relative;
    height: 10px;
    background: #29b6f6;
}

/* Style stripes */
.pro-bar-container.style-2 {
    -webkit-border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.pro-bar-container.style-2 .pro-bar {
    -webkit-border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -moz-border-radius-topright: 10px;
    -moz-border-radius-bottomright: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomleft: 10px;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.stripes .pro-bar {
    background-color: #ff9800;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent)));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    -webkit-background-size: 20px 20px;
    background-size: 20px 20px;
}

.stripes .green {
    background-color: #4caf50;
}

.stripes .blue {
    background-color: #29b6f6;
}

.text-in-bar {
    position: absolute;
    top: -28px;
    right: 0px;
    color: #555555;
}

.pro-bar.bar-100 {
    width: 100%;
}

.pro-bar.bar-99 {
    width: 99%;
}

.pro-bar.bar-98 {
    width: 98%;
}

.pro-bar.bar-97 {
    width: 97%;
}

.pro-bar.bar-96 {
    width: 96%;
}

.pro-bar.bar-95 {
    width: 95%;
}

.pro-bar.bar-94 {
    width: 94%;
}

.pro-bar.bar-93 {
    width: 93%;
}

.pro-bar.bar-92 {
    width: 92%;
}

.pro-bar.bar-91 {
    width: 91%;
}

.pro-bar.bar-90 {
    width: 90%;
}

.pro-bar.bar-89 {
    width: 89%;
}

.pro-bar.bar-88 {
    width: 88%;
}

.pro-bar.bar-87 {
    width: 87%;
}

.pro-bar.bar-86 {
    width: 86%;
}

.pro-bar.bar-85 {
    width: 85%;
}

.pro-bar.bar-84 {
    width: 84%;
}

.pro-bar.bar-83 {
    width: 83%;
}

.pro-bar.bar-82 {
    width: 82%;
}

.pro-bar.bar-81 {
    width: 81%;
}

.pro-bar.bar-80 {
    width: 80%;
}

.pro-bar.bar-79 {
    width: 79%;
}

.pro-bar.bar-78 {
    width: 78%;
}

.pro-bar.bar-77 {
    width: 77%;
}

.pro-bar.bar-76 {
    width: 76%;
}

.pro-bar.bar-75 {
    width: 75%;
}

.pro-bar.bar-74 {
    width: 74%;
}

.pro-bar.bar-73 {
    width: 73%;
}

.pro-bar.bar-72 {
    width: 72%;
}

.pro-bar.bar-71 {
    width: 71%;
}

.pro-bar.bar-70 {
    width: 70%;
}

.pro-bar.bar-69 {
    width: 69%;
}

.pro-bar.bar-68 {
    width: 68%;
}

.pro-bar.bar-67 {
    width: 67%;
}

.pro-bar.bar-66 {
    width: 66%;
}

.pro-bar.bar-65 {
    width: 65%;
}

.pro-bar.bar-64 {
    width: 64%;
}

.pro-bar.bar-63 {
    width: 63%;
}

.pro-bar.bar-62 {
    width: 62%;
}

.pro-bar.bar-61 {
    width: 61%;
}

.pro-bar.bar-60 {
    width: 60%;
}

.pro-bar.bar-59 {
    width: 59%;
}

.pro-bar.bar-58 {
    width: 58%;
}

.pro-bar.bar-57 {
    width: 57%;
}

.pro-bar.bar-56 {
    width: 56%;
}

.pro-bar.bar-55 {
    width: 55%;
}

.pro-bar.bar-54 {
    width: 54%;
}

.pro-bar.bar-53 {
    width: 53%;
}

.pro-bar.bar-52 {
    width: 52%;
}

.pro-bar.bar-51 {
    width: 51%;
}

.pro-bar.bar-50 {
    width: 50%;
}

.pro-bar.bar-49 {
    width: 49%;
}

.pro-bar.bar-48 {
    width: 48%;
}

.pro-bar.bar-47 {
    width: 47%;
}

.pro-bar.bar-46 {
    width: 46%;
}

.pro-bar.bar-45 {
    width: 45%;
}

.pro-bar.bar-44 {
    width: 44%;
}

.pro-bar.bar-43 {
    width: 43%;
}

.pro-bar.bar-42 {
    width: 42%;
}

.pro-bar.bar-41 {
    width: 41%;
}

.pro-bar.bar-40 {
    width: 40%;
}

.pro-bar.bar-39 {
    width: 39%;
}

.pro-bar.bar-38 {
    width: 38%;
}

.pro-bar.bar-37 {
    width: 37%;
}

.pro-bar.bar-36 {
    width: 36%;
}

.pro-bar.bar-35 {
    width: 35%;
}

.pro-bar.bar-34 {
    width: 34%;
}

.pro-bar.bar-33 {
    width: 33%;
}

.pro-bar.bar-32 {
    width: 32%;
}

.pro-bar.bar-31 {
    width: 31%;
}

.pro-bar.bar-30 {
    width: 30%;
}

.pro-bar.bar-29 {
    width: 29%;
}

.pro-bar.bar-28 {
    width: 28%;
}

.pro-bar.bar-27 {
    width: 27%;
}

.pro-bar.bar-26 {
    width: 26%;
}

.pro-bar.bar-25 {
    width: 25%;
}

.pro-bar.bar-24 {
    width: 24%;
}

.pro-bar.bar-23 {
    width: 23%;
}

.pro-bar.bar-22 {
    width: 22%;
}

.pro-bar.bar-21 {
    width: 21%;
}

.pro-bar.bar-20 {
    width: 20%;
}

.pro-bar.bar-19 {
    width: 19%;
}

.pro-bar.bar-18 {
    width: 18%;
}

.pro-bar.bar-17 {
    width: 17%;
}

.pro-bar.bar-16 {
    width: 16%;
}

.pro-bar.bar-15 {
    width: 15%;
}

.pro-bar.bar-14 {
    width: 14%;
}

.pro-bar.bar-13 {
    width: 13%;
}

.pro-bar.bar-12 {
    width: 12%;
}

.pro-bar.bar-11 {
    width: 11%;
}

.pro-bar.bar-10 {
    width: 10%;
}

.pro-bar.bar-9 {
    width: 9%;
}

.pro-bar.bar-8 {
    width: 8%;
}

.pro-bar.bar-7 {
    width: 7%;
}

.pro-bar.bar-6 {
    width: 6%;
}

.pro-bar.bar-5 {
    width: 5%;
}

.pro-bar.bar-4 {
    width: 4%;
}

.pro-bar.bar-3 {
    width: 3%;
}

.pro-bar.bar-2 {
    width: 2%;
}

.pro-bar.bar-1 {
    width: 1%;
}

.pro-bar.bar-0 {
    width: 0%;
}

/* #Services boxes
================================================== */
.service-box img {
    float: left;
}

.service-box .service-content {
    padding-left: 78px;
}

.service-box .service-content h3 {
    font-size: 15px;
    font-weight: bold;
    color: #555555;
    line-height: 24px;
    margin: 0px 0px 8px 0px;
    text-transform: none;
}

.service-style2 {
    text-align: center;
    border: 1px solid #ebebeb;
    padding: 50px 10px;
    min-height: 270px;
    vertical-align: middle;
}

.service-style2 img {
    float: none;
    display: inline-block;
}

.service-style2 .service-content {
    margin-top: 25px;
    padding-left: 0px;
}

/* #Pricing Table
================================================== */
.pricing-item {
    background: #ffffff;
    text-align: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #f2f2f2;
    border-top: 5px solid #29b6f6;
    padding: 38px 0px;
}

.pricing-item .plan-price {
    color: #333333;
    font-size: 72px;
    padding-bottom: 35px;
    line-height: 36px;
}

.pricing-item .plan-price sup {
    font-size: 47px;
}

.pricing-item .plan-price span {
    font-size: 13px;
    font-weight: normal;
    font-family: 'Raleway', sans-serif;
}

.pricing-item .plan-name {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 36px;
    margin-bottom: 18px;
}

.pricing-item .plan-details {
    margin-bottom: 35px;
}

.pricing-item .plan-details ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.pricing-item .plan-details ul li {
    margin-bottom: 8px;
}

.pricing-item .plan-btn {
    margin: 0px;
}

.pricing-item.featured {
    border-top: 5px solid #ff9800;
}

.pricing-item.featured .btn-primary {
    background-color: #ff9800;
}

.pricing-item.featured .btn-primary:hover {
    opacity: .8;
}

.pricing-table-2 {
    border: 3px solid #f2f2f2;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    padding: 45px 0px 35px 0px;
}

.pricing-table-2 img {
    display: inline-block;
}

.pricing-table-2 .plan-name {
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 30px;
    color: #555555;
}

.pricing-table-2 .plan-price {
    display: inline-block;
    position: relative;
    font-family: 'Lato', sans-serif;
    color: #29b6f6;
}

.pricing-table-2 .plan-price span {
    position: absolute;
    right: -50px;
    top: 10px;
    display: inline-block;
    color: #555555;
}

.pricing-table-2 .plan-price sup {
    position: absolute;
    top: 5px;
    left: -25px;
}

.pricing-table-2.featured {
    border: 3px solid #ff9800;
    padding: 60px 0px 50px;
    margin: -22px -3px 0px;
    position: relative;
}

.pricing-table-2.featured .plan-price {
    color: #ff9800;
}

/* #Clients Logo
================================================== */
.our-partner .item {
    text-align: center;
}

.our-partner .owl-controls {
    margin-top: 40px;
}

/* #Newsletter
================================================== */
.quick_newsletter .newsletter-info {
    color: #f8f8f8;
}

.quick_newsletter .newsletter-info h3 {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 24px;
    margin: 0px;
    color: #f8f8f8;
}

.quick_newsletter .newsletter-element .input-text {
    background-color: #f8f8f8;
    padding: 15px 20px;
    display: inline-block;
    border: none;
    outline: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

.quick_newsletter .newsletter-element .newsletter-submit {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    padding: 14px 30px 14px 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: auto;
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: 2px solid #ffffff;
}

.quick_newsletter .newsletter-element .newsletter-submit:hover {
    background-color: #ffffff;
    color: #00418a;
}

.quick_newsletter .newsletter-element .newsletter-submit i {
    margin-right: 5px;
}

.quick_newsletter p {
    margin-bottom: 0px;
}

/* #Team
================================================== */
.team-box .team-inner {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.team-box .team-inner img {
    width: 100%;
    display: block;
}

.team-box .team-inner .mask {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: 0;
    background: rgba(41, 182, 246, 0.8);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.team-box:hover .mask {
    opacity: 1;
}

.team-box .team-inner ul {
    list-style: none;
    position: absolute;
    z-index: 3;
    top: -30px;
    margin: 0px;
    padding: 0px;
    width: 100%;
    left: 0;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.team-box .team-inner ul li {
    display: inline-block;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
}

.team-box:hover ul {
    top: 50%;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.team-box .team-inner ul li a {
    font-family: 'FontAwesome';
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #f2f2f2;
    display: inline-block;
    color: #f2f2f2;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.team-box .team-inner ul li a:hover {
    background-color: #f2f2f2;
    color: #29b6f6;
}

.team-box h6 {
    margin-top: 30px;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    color: #333333;
}

.team-box .subtext {
    text-align: center;
    color: #555555;
    font-size: 13px;
    line-height: 26px;
}

.about-text-intro,
.service-text-intro {
    padding: 24px 50px 44px;
}

.about-text-intro h2,
.service-text-intro h2 {
    font-size: 50px;
    line-height: 21px;
    margin-bottom: 35px;
}

.about-text-intro p,
.service-text-intro p {
    font-family: 'Droid Serif', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    font-style: italic;
}

.service-text-intro {
    padding: 24px 50px 34px;
}

.btn-service-intro .btn {
    margin: 0px 10px;
}

.box-intro-video {
    position: relative;
    margin-bottom: -145px;
}

.box-intro-video .btn-intro-video {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -35px;
    color: #ffffff;
    font-size: 30px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background: #29b6f6;
    text-align: center;
}

.box-intro-video .btn-intro-video:hover {
    background: rgba(41, 182, 246, 0.9);
}

/* #Project
================================================== */
#projects-grid {
    position: relative;
    padding-bottom: 30px;
}

#projects-grid .project-item-2 {
    margin-bottom: 30px;
}

#projects-grid:after {
    content: '';
    display: block;
    clear: both;
}

.project-item {
    position: relative;
    float: left;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    margin-left: -1px;
    -webkit-transform: translate3d(1px, 0, 0);
    transform: translate3d(1px, 0, 0);
}

.projects-grid-5 .project-item {
    width: 20%;
}

.projects-grid .project-item {
    width: 25%;
}

.projects-boxes .project-item {
    margin-bottom: 30px;
}

.project-item-inner {
    overflow: hidden;
    position: relative;
}

.project-grid-2 #filter {
    padding-left: 20px;
    text-align: left;
    margin-bottom: 40px;
}

.project-grid-2 #filter li {
    margin-left: 0px;
    margin-right: 22px;
}

.col-md-3 {
    margin-right: -1px;
}

.project-page #filter {
    padding-left: 0px;
    text-align: left;
    margin-bottom: 40px;
}

.project-page #filter li {
    margin-left: 0px;
    margin-right: 22px;
}

#filter {
    position: relative;
    width: 100%;
    text-align: center;
}

#filter li {
    display: inline-block;
    margin-left: 22px;
}

#filter:after {
    content: '';
    display: block;
    clear: both;
}

#filter li a {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 13px;
    padding: 0px;
    outline: none;
    color: #323232;
    text-decoration: none;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

#filter li .current,
#filter li a:hover {
    color: #29b6f6;
}

.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
}

.isotope {
    -webkit-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    transition-property: transform, opacity;
}

/* Project Detail */
.project-slider .owl-controls .owl-pagination {
    position: absolute;
    top: 30px;
    right: 30px;
}

.project-slider .owl-controls .owl-pagination .owl-page span {
    margin: 0px 4px;
}

.project-slider .owl-controls .owl-buttons .owl-prev,
.project-slider .owl-controls .owl-buttons .owl-next {
    border: none;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    position: absolute;
    top: 50%;
    margin: 0px;
    margin-top: -18px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

.project-slider .owl-controls .owl-buttons .owl-prev {
    left: 15px;
}

.project-slider .owl-controls .owl-buttons .owl-next {
    right: 15px;
}

.project-info h2 {
    margin-bottom: 30px;
}

ul.project-list {
    list-style: none;
    padding-left: 0px;
    margin-top: 20px;
}

ul.project-list li {
    margin-bottom: 10px;
}

ul.project-list li strong {
    display: inline-block;
    margin-right: 10px;
    min-width: 45px;
}

/* #Contact
================================================== */
.map-canvas {
    height: 400px;
}

.intro-text {
    padding: 24px 50px 44px;
}

.intro-text h2 {
    font-size: 50px;
    text-transform: uppercase;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 35px;
}

.intro-text p {
    font-family: 'Droid Serif', Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    font-style: italic;
}

.wpcf7-form input[type=text],
.wpcf7-form input[type=email],
.wpcf7-form input[type=file],
.wpcf7-form input[type=date],
.wpcf7-form input[type=datetime],
.wpcf7-form input[type=password],
.wpcf7-form textarea {
    border: 1px solid #D1D3D4;
    background: #f4f6f7;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    color: #5F5D5D;
    padding: 10px 20px 11px;
    outline: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 5px;
}

.wpcf7-form input[type=text]:focus,
.wpcf7-form input[type=email]:focus,
.wpcf7-form input[type=file]:focus,
.wpcf7-form input[type=date]:focus,
.wpcf7-form input[type=datetime]:focus,
.wpcf7-form input[type=password]:focus,
.wpcf7-form textarea:focus {
    background-color: #fff;
    border-color: #29b6f6;
    color: #1b1b1b;
}

.col-full {
    width: 100%;
}

.col-one-third {
    float: left;
    width: 31.5%;
    margin-bottom: 20px;
}

.margin-one-third {
    margin-right: 2.626%;
    margin-left: 2.626%;
}

.wpcf7-form textarea {
    min-height: 145px;
    margin-top: 0px;
}

.contact-list {
    list-style: none;
    padding: 0px;
    margin-bottom: 40px;
    padding-top: 10px;
}

.contact-list li {
    margin-bottom: 10px;
}

.contact-list li i {
    display: inline-block;
    margin-right: 5px;
    font-size: 13px;
    color: #29b6f6;
}

.info-branch h5 {
    margin-bottom: 20px;
}

.info-branch p {
    margin-bottom: 20px;
}

/* FAQs Page */
.bg-dark {
    background-color: #303335;
    color: #999999;
}

.content-box {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 40px;
    width: 100%;
}

.content-box .btn {
    margin-top: 20px;
}

.text-white {
    color: #ffffff !important;
    font-size: 16px;
    margin-bottom: 30px;
}

/* #Coming Soon
================================================== */
.coming-soon-content {
    display: table;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.coming-soon-content .social-icons a {
    background-color: transparent;
    color: #555;
    font-size: 15px;
    display: inline-block;
    margin: 0px 8px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.coming-soon-content .social-icons a:hover {
    color: rgba(85, 85, 85, 0.8);
}

.newsletter-comingsoon {
    display: inline-block;
    width: auto;
}

.newsletter-comingsoon .newsletter-submit {
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    margin-left: 10px;
    padding: 15px 30px 15px 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: auto;
    position: relative;
    display: inline-block;
    background-color: #555555;
}

.newsletter-comingsoon .newsletter-submit:hover {
    background-color: rgba(85, 85, 85, 0.9);
}

.newsletter-comingsoon .input-text {
    background-color: #fff;
    padding: 15px 20px;
    display: inline-block;
    border: none;
    outline: none;
    min-width: 270px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    margin-right: 10px;
}

/* #Accordion
================================================== */
.smk_accordion {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative;
}

.smk_accordion .accordion_in {
    margin-bottom: 10px;
    margin-top: -1px;
    overflow: hidden;
    position: relative;
    z-index: 10;
}

.smk_accordion .accordion_in .acc_head {
    background: #fff;
    border: 1px solid #ededed;
    color: #212121;
    cursor: pointer;
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 11px;
    padding-top: 10px;
    position: relative;
    text-align: left;
    -webkit-transition: .3s;
    transition: .3s;
}

.smk_accordion .accordion_in .acc_head i {
    margin-right: 8px;
}

.accordion-1.smk_accordion .accordion_in .acc_head {
    -webkit-border-radius: 40px;
    border-radius: 40px;
}

.smk_accordion .accordion_in .acc_head:hover {
    color: #fff;
}

.smk_accordion .accordion_in.acc_active > .acc_head {
    color: #fff;
}

.smk_accordion .accordion_in .acc_content {
    background: #fff;
    color: #606060;
    padding: 25px 20px 20px 30px;
    border: 1px solid #ededed;
}

.accordion-1.smk_accordion .accordion_in .acc_content {
    border: none;
}

.smk_accordion .accordion_in .acc_content h1:first-of-type,
.smk_accordion .accordion_in .acc_content h2:first-of-type,
.smk_accordion .accordion_in .acc_content h3:first-of-type,
.smk_accordion .accordion_in .acc_content h4:first-of-type,
.smk_accordion .accordion_in .acc_content h5:first-of-type,
.smk_accordion .accordion_in .acc_content h6:first-of-type {
    margin-top: 5px;
}

.smk_accordion .accordion_in.acc_active > .acc_content {
    display: block;
}

.smk_accordion.acc_with_icon .accordion_in .acc_head,
.smk_accordion.acc_with_icon .accordion_in .acc_content {
    padding-left: 30px;
}

.smk_accordion .accordion_in .acc_head:hover,
.smk_accordion .accordion_in.acc_active > .acc_head {
    background-color: #29b6f6;
    border-color: #29b6f6;
}

/* #Tabs
================================================== */
.tabs {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    background-color: #ffffff;
}

.tabs > header {
    width: 100%;
    float: left;
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: #ededed;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #ededed;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #ededed;
}

.tabs > header > h4 {
    width: auto;
    float: left;
    padding: 0px 20px;
    line-height: 50px;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #ededed;
    cursor: pointer;
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: #ededed;
    margin-top: -3px;
    color: #666666;
    margin-bottom: 0px;
    font-size: 13px;
}

.tabs > header > h4.active {
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: #29B6F6;
    color: #333333;
}

.tabs .tabs-main {
    border: 1px solid #ededed;
    width: 100%;
    float: left;
    padding: 25px;
    padding-bottom: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.tabs .tabs-main .tabs-item {
    display: none;
    width: 100%;
    float: left;
}

/* Tabs style 2 */
.tabs.tabs-2 > header {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.tabs.tabs-2 > header > h4 {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    padding-left: 0px;
    padding-right: 40px;
    text-align: left;
    position: relative;
}

.tabs.tabs-2 > header > h4:last-child {
    padding-right: 0px;
}

.tabs.tabs-2 > header > h4.active::before {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 3px;
    width: 40px;
    background-color: #29B6F6;
    z-index: 1000;
}

/* Tabs style 3 */
.tabs.tabs-3 > header {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.tabs.tabs-3 > header > h4 {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    text-transform: none;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 30px;
}

.tabs.tabs-3 > header > h4.active {
    background-color: #29b6f6;
    color: #fff;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
}

.tabs.tabs-3 .tabs-main {
    border: 0px solid #ededed;
    padding: 15px 0px 0px 0px;
}

/* Tabs style 4 */
.tabs.tabs-4 > header {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 3px;
    border-bottom-color: #29b6f6;
    border-bottom-style: solid;
}

.tabs.tabs-4 > header > h4 {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    text-transform: none;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 30px;
}

.tabs.tabs-4 > header > h4.active {
    background-color: #29b6f6;
    color: #fff;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}

.tabs.tabs-4 .tabs-main {
    border: 0px solid #ededed;
    padding: 20px 0px 0px 0px;
}

.accordion .tabs {
    z-index: 2;
}

/* Tooltip Style */
.tipper {
    height: 1px;
    left: -99999px;
    position: absolute;
    pointer-events: none;
    top: -99999px;
    width: 1px;
    z-index: 100000;
}

.tipper .tipper-content {
    background: rgba(0, 0, 0, 0.95);
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    display: block;
    float: left;
    font-family: 'Lato', sans-serif;
    font-size: 10px;
    margin: 0;
    padding: 6px 20px;
    position: relative;
    white-space: nowrap;
}

.tipper .tipper-caret {
    background: url('/images/tipper-icon.png') no-repeat;
    display: block;
    height: 11px;
    position: absolute;
    width: 100%;
}

.tipper.right .tipper-content {
    -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.25);
}

.tipper.right .tipper-caret {
    background-position: left center;
    left: -5px;
    top: 0;
}

.tipper.left .tipper-content {
    -webkit-box-shadow: -1px 0 3px rgba(0, 0, 0, 0.25);
    box-shadow: -1px 0 3px rgba(0, 0, 0, 0.25);
}

.tipper.left .tipper-caret {
    background-position: right center;
    right: -5px;
    top: 0;
}

.tipper.top .tipper-caret,
.tipper.bottom .tipper-caret {
    display: block;
    float: none;
    height: 5px;
    width: 12px;
    margin-left: -6px;
}

.tipper.top .tipper-content {
    -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.25);
}

.tipper.top .tipper-caret {
    background-position: center bottom;
    bottom: -5px;
    left: 0;
    text-align: center;
}

.tipper.bottom .tipper-content {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

.tipper.bottom .tipper-caret {
    background-position: center top;
    top: -5px;
    left: 0;
}

.fontawesome {
    font-family: 'FontAwesome';
    display: inline-block;
    font-size: 13px;
}

/* Footer
----------------------------------------------------------*/
/* Footer 1 */
footer.bg-color-1 {
    background: #042433;
    color: #999999;
}

footer .main-footer {
    padding: 50px 0px 50px 0px;
}

footer .main-footer .widget-title {
    color: #ffffff;
    margin-bottom: 35px;
    margin-top: 25px;
}

footer .main-footer .widget-inner ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

footer .main-footer .widget-inner ul li {
    margin-bottom: 10px;
    padding-left: 10px;
    position: relative;
}

footer .main-footer .widget-inner ul li:before {
    font-family: 'FontAwesome';
    display: inline-block;
    font-size: 13px;
    content: "\f105";
    color: #e5e5e5;
    position: absolute;
    left: 0px;
    top: 0px;
}

footer .main-footer .widget-inner ul li a {
    color: #e5e5e5;
    text-decoration: none;
    -webkit-transition: .5s;
    transition: .5s;
}

footer .main-footer .widget-inner ul li a:hover {
    color: #29b6f6;
}

footer .main-footer .widget-inner .logo-footer {
    display: inline-block;
    margin-bottom: 35px;
}

footer .main-footer .widget-inner .social-icons {
    margin-top: 20px;
}

footer .main-footer .widget-inner .social-icons a {
    display: inline-block;
    text-decoration: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    color: #ffffff;
    margin-right: 10px;
    float: left;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

footer .sub-footer {
    padding: 30px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.widget-title {
    font-size: 18px;
}

.newsletter form {
    position: relative;
    margin-top: 20px;
}

.newsletter form input[type="email"] {
    height: 40px;
    line-height: 40px;
    padding-left: 44px;
    padding-right: 5px;
    background: #1e3a48;
    border: none;
    outline: none;
    color: #cccccc;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.newsletter form input[type="email"]:focus {
    border: 1px solid #29b6f6;
}

.newsletter form .fa-envelope-o {
    position: absolute;
    top: 12px;
    left: 16px;
    color: #8a98a0;
    font-size: 15px;
}

.newsletter form input[type="submit"] {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 10px 30px 10px 30px;
    margin-top: 5px;
    display: inline-block;
    text-decoration: none;
    border: none;
    font-size: 13px;
    font-weight: bold;
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    background: #29b6f6;
}

.newsletter form input[type="submit"]:hover {
    background-color: #2C3E50;
    color: #e5e5e5;
}

/* Footer 2 */
footer.bg-color-2 {
    background: #f8f8f8;
    color: #666666;
}

footer.footer-2 .logo-footer {
    margin-bottom: 35px;
}

footer.footer-2 .social-icons-2 {
    margin-top: 20px;
}

footer.footer-2 .social-icons-2 a {
    display: inline-block;
    text-decoration: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background: transparent;
    color: #888888;
    margin-right: 5px;
    border: 1px solid #e6e6e6;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

footer.footer-2 .social-icons-2 a:hover {
    background: #29b6f6;
    border-color: #29b6f6;
    color: #ffffff;
}

footer.footer-2 .social-icons-2 a.facebook:hover {
    background-color: #02549F;
    border-color: #02549F;
}

footer.footer-2 .social-icons-2 a.vk:hover {
    background-color: #4C75A3;
    border-color: #4C75A3;
}

footer.footer-2 .social-icons-2 a.vimeo:hover {
    background-color: #1AB7EA;
    border-color: #1AB7EA;
}

footer.footer-2 .social-icons-2 a.tumblr:hover {
    background-color: #35465D;
    border-color: #35465D;
}

footer.footer-2 .social-icons-2 a.twitter:hover {
    background-color: #00ACED;
    border-color: #00ACED;
}

footer.footer-2 .social-icons-2 a.behance:hover {
    background-color: #1769FF;
    border-color: #1769FF;
}

footer.footer-2 .social-icons-2 a.instagram:hover {
    background-color: #DDCCBA;
    border-color: #DDCCBA;
}

footer.footer-2 .social-icons-2 a.pinterest:hover {
    background-color: #CD2129;
    border-color: #CD2129;
}

footer.footer-2 .social-icons-2 a.flickr:hover {
    background-color: #FF0084;
    border-color: #FF0084;
}

footer.footer-2 .social-icons-2 a.dribbble:hover {
    background-color: #EA4C89;
    border-color: #EA4C89;
}

footer.footer-2 .social-icons-2 a.google:hover {
    background-color: #D11516;
    border-color: #D11516;
}

footer.footer-2 .social-icons-2 a.youtube:hover {
    background-color: #E12A27;
    border-color: #E12A27;
}

footer.footer-2 .social-icons-2 a.linkedin:hover {
    background-color: #006699;
    border-color: #006699;
}

footer.footer-2 .social-icons-2 a.skype:hover {
    background-color: #00A7E5;
    border-color: #00A7E5;
}

footer.footer-2 .footer-contact {
    margin-top: 20px;
    margin-bottom: 35px;
}

footer.footer-2 .footer-contact ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    max-width: 450px;
    display: inline-block;
}

footer.footer-2 .footer-contact ul li {
    display: inline-block;
    margin-left: 15px;
    margin-bottom: 5px;
}

footer.footer-2 .footer-contact ul li .fa {
    margin-right: 5px;
}

footer.footer-2 .footer-contact ul li span {
    display: inline-block;
    margin-left: 15px;
}

footer.footer-2 .sub-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

/* Back To Top */
#to-the-top {
    display: none;
    position: fixed;
    text-align: center;
    right: 30px;
    bottom: 30px;
    color: #fff;
    cursor: pointer;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    z-index: 10000;
    font-size: 18px;
    height: 32px;
    width: 32px;
    line-height: 32px;
    background-color: rgba(0, 0, 0, 0.4);
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -webkit-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
}

#to-the-top:hover {
    background-color: #29b6f6;
}

.banner-feature-shop {
    padding: 80px 0px 195px;
    position: relative;
}

.banner-feature-shop img {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
}

.banner-feature-shop .sale-featured {
    position: relative;
    z-index: 2;
}

.banner-feature-shop .sale-featured h2 {
    font-size: 58px;
    font-weight: bold;
    line-height: 72px;
    color: #333333;
    margin-bottom: 30px;
}

.banner-feature-shop .sale-featured h5 {
    font-family: 'Droid Serif', Arial, Helvetica, sans-serif;
    font-size: 25px;
    line-height: 30px;
    font-style: italic;
    color: #555555;
    text-transform: none;
    font-weight: 300;
    margin-bottom: 15px;
}

.banner-feature-shop .sale-featured h5 span {
    font-size: 36px;
    color: #29b6f6;
}

.compact-sorting {
    margin-bottom: 40px;
}

.compact-sorting:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.compact-sorting .woocommerce-ordering {
    float: right;
}

.compact-sorting .woocommerce-ordering select {
    text-transform: uppercase;
    padding: 5px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: #dddddd;
}

.compact-sorting .woocommerce-ordering select:focus {
    outline: none;
}

.compact-sorting .woocommerce-result-count {
    float: left;
    text-transform: uppercase;
}

.product-item {
    margin-bottom: 48px;
}

.product-item figure {
    position: relative;
}

.product-item figure figcaption {
    margin: 0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    overflow-x: hidden;
    font-style: normal;
}

.product-item figure figcaption a.button {
    position: absolute;
    left: 50%;
    top: 0%;
    margin-left: -65px;
    margin-top: -150px;
    z-index: 3;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 40px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    padding: 10px 30px 10px 30px;
    display: inline-block;
    text-decoration: none;
    border: none;
    font-size: 13px;
    font-weight: bold;
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    line-height: 24px;
    text-align: center;
    background: #29b6f6;
    color: #fff;
}

.product-item:hover figure figcaption {
    background-color: rgba(86, 86, 86, 0.3);
}

.product-item:hover figure figcaption a.button {
    top: 50%;
    margin-top: -20px;
}

.product-item:hover figure figcaption a.button:hover {
    background-color: #2C3E50;
}

.product-item .product-detail {
    text-align: center;
}

.product-item .product-detail h3 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 15px;
}

.product-item .product-detail h3 a {
    color: #888888;
    text-decoration: none;
    outline: none;
}

.product-item .product-detail h3 a:hover {
    color: #29b6f6;
}

.product-item .product-detail span.price span.amount {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.main-sidebar .widget ul li {
    margin-bottom: 8px;
}

.widget ul li .count {
    float: right;
}

.main-sidebar .product_list_widget li {
    clear: both;
    margin-bottom: 15px;
}

.main-sidebar .product_list_widget li:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.main-sidebar .product_list_widget li img {
    width: 80px;
    height: auto;
    display: block;
    float: left;
    margin-right: 10px;
}

.main-sidebar .product_list_widget li .product-title {
    font-size: 13px;
    font-weight: bold;
    text-transform: capitalize;
}

.main-sidebar .product_list_widget li span.amount {
    display: block;
}

.main-sidebar .widget ul.product_list_widget li:before {
    content: '';
    position: static;
    margin: 0px;
    padding: 0px;
}

.breadcrumbs {
    color: #555555;
}

.breadcrumbs b {
    font-weight: normal;
    display: inline-block;
    margin: 0px 5px;
}

.related-product-item {
    margin: 0px 15px 20px;
}

.single-product-detail {
    margin-bottom: 30px;
}

.single-product-detail h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 15px;
}

.single-product-detail .price {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.product-data ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.product-data ul li {
    display: inline-block;
    float: left;
    margin-right: 30px;
}

.product-data ul li input {
    background-color: #f2f2f2;
    color: #666666;
    font-size: 15px;
    border: none;
    padding: 10px 10px;
    width: 80px;
    font-weight: bold;
}

.product-data ul li span {
    display: inline-block;
    margin-right: 30px;
}

.star-rating {
    color: #ffb636;
    margin-bottom: 20px;
}

.star-rating span {
    color: #666666;
    display: inline-block;
    margin-left: 10px;
}

#reviews #comments ol.commentlist li {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 center;
    border: 0 none;
    margin: 0 0 20px;
    padding: 0;
    position: relative;
}

.commentlist {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.commentlist .comment_container img {
    background: #ebe9eb;
    border: 1px solid #e4e1e3;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: auto;
    margin: 0;
    padding: 3px;
    width: 50px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.commentlist .comment_container .comment-text {
    margin-left: 65px;
    padding: 1em 1em 0;
    border: 1px solid #e4e1e3;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.commentlist .comment_container .comment-text .star-rating {
    position: absolute;
    right: 1em;
    top: 15px;
}

.product-detail-image {
    margin-bottom: 20px;
}

.product-detail-image img {
    border: 1px solid #f2f2f2;
}

.product-detail-image-thumb {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.product-detail-image-thumb li {
    display: inline-block;
    width: 120px;
    height: 120px;
    float: left;
    margin-right: 21px;
}

.product-detail-image-thumb li:last-child {
    margin-right: 0px;
}

.product-detail-image-thumb li img {
    display: block;
    max-width: 100%;
    border: 1px solid #f2f2f2;
}

/* Cart Page */
table.shop_table {
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    font-size: 15px;
}

table.cart td,
table.cart th {
    vertical-align: middle;
}

table,
th,
td {
    border: 0px solid #e6e6e6;
}

table.shop_table th {
    font-weight: normal;
    padding: 9px 12px;
    border-width: 0 0px 3px 0;
}

table.shop_table td {
    border-width: 0 0px 1px 0;
    padding: 20px 12px;
    vertical-align: middle;
}

table.shop_table tr:last-child td {
    border-width: 0 0px 0px 0;
    padding-top: 30px;
    padding-left: 0px;
    padding-right: 0px;
}

.product-remove a.remove {
    border: 0 none;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    color: #fff;
    display: block;
    font-size: 15px;
    font-weight: 700;
    height: 13px;
    line-height: 13px;
    text-align: center;
    text-decoration: none;
    width: 13px;
    background-color: #cccccc;
}

.product-quantity input {
    background-color: #f2f2f2;
    color: #666666;
    font-size: 15px;
    border: none;
    padding: 10px 10px;
    width: 80px;
    font-weight: normal;
}

.coupon .input-text,
.shipping-calculator-form .input-text,
.shipping-calculator-form select {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 5px;
    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    border: none;
    outline: none;
    color: #333;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    font-size: 13px;
}

.coupon input[type="text"] {
    width: 200px;
    margin-right: 25px;
}

.coupon {
    float: left;
}

.actions .btn {
    float: right;
}

.cart-collaterals {
    padding: 40px;
    border: 1px solid #f2f2f2;
}

.cart_totals table.shop_table th,
.cart_totals table.shop_table td {
    padding: 20px 12px;
    border-width: 0 0px 1px 0;
    border-style: dashed;
    border-color: #d9d9d9;
    color: #555;
}

.cart_totals table.shop_table tr:last-child th {
    border-width: 0 0px 0px 0;
    padding-top: 30px;
}

.cart_totals table.shop_table th {
    font-weight: bold;
    padding-left: 0px;
}

table.shop_table tr.order-total th,
table.shop_table tr.order-total td {
    font-size: 24px;
    line-height: 22px;
}

.shipping-calculator-form p {
    margin-bottom: 15px;
}

.shipping-calculator-button {
    font-weight: bold;
    display: inline-block;
}

.shop-checkout .form-row .input-text,
.shop-checkout .form-row select {
    background-color: #f2f2f2;
    color: #888888;
    border: 0px solid #d9d9d9;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 5px;
    width: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.shop-checkout .form-row .input-text:focus,
.shop-checkout .form-row select:focus {
    outline: none;
}

form .form-row-first,
form .form-row-last {
    float: left;
    overflow: visible;
    width: 49%;
}

form .form-row-first {
    margin-right: 2%;
}

form .form-row .input-checkbox {
    display: inline;
    margin: -2px 8px 0 0;
    text-align: center;
    vertical-align: middle;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

form .form-row label.checkbox {
    display: inline;
    padding: 0px;
    font-weight: normal;
}

.wc_payment_methods {
    list-style: none;
    padding: 0px;
}

.cart_totals table.shop-checkout th,
.cart_totals table.shop-checkout td {
    padding: 15px 0px;
}

.cart_totals table.shop_table .no-border th,
.cart_totals table.shop_table .no-border td {
    border-width: 0 0px 0px 0;
    padding: 20px 0px 0px;
}

.cart_totals table.shop-checkout td:last-child,
.cart_totals table.shop-checkout th:last-child {
    text-align: right;
}

.woocommerce-checkout-payment {
    border-top-width: 1px;
    border-top-style: dashed;
    border-top-color: #d9d9d9;
    padding-top: 30px;
}

.woocommerce-checkout-payment li {
    margin-bottom: 10px;
}

.woocommerce-checkout-payment li label {
    font-size: 15px;
    font-weight: normal;
    margin-left: 5px;
}

/* page-numbers */
.woocommerce-pagination {
    width: 100%;
    text-align: center;
}

.woocommerce-pagination .page-numbers {
    padding-left: 0;
    margin: 20px 0;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    display: inline-block;
}

.woocommerce-pagination .page-numbers li {
    list-style: none;
    display: inline-block;
    text-align: center;
    float: left;
}

.woocommerce-pagination .page-numbers > li > a,
.woocommerce-pagination .page-numbers > li > span {
    padding: 0px;
    margin-left: 7px;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: block;
    color: #555555;
    text-decoration: none;
    background-color: #e6e6e6;
    border: 0px solid #ddd;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.woocommerce-pagination .page-numbers > li:first-child > a,
.woocommerce-pagination .page-numbers > li:first-child > span {
    margin-left: 0;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.woocommerce-pagination .page-numbers > li:last-child > a,
.woocommerce-pagination .page-numbers > li:last-child > span {
    -webkit-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.woocommerce-pagination .page-numbers > li > span.current,
.woocommerce-pagination .page-numbers > li > a:hover {
    background-color: #29b6f6;
    color: #ffffff;
}

/* #Media Queries
================================================== */
@media only screen and (min-width: 960px) and (max-width: 1199px) {
    nav.site-desktop-menu > ul > li > a {
        padding: 0px 10px;
    }

    .header-buttons > * {
        margin-left: 10px;
    }

    .site-header-2 .header-buttons > *,
    .site-header-2 .header-contact ul li {
        margin-left: 20px;
    }

    .site-header-2 .header-contact ul li.border-line {
        margin-left: 15px;
    }

    .header-contact ul li.border-line {
        margin-left: 5px;
    }

    .latest-news-items-2 .latest-blog-post-img {
        float: none;
        max-width: inherit;
    }

    .latest-news-items-2 .latest-blog-post-details {
        padding-left: 0px;
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .site-header .stuck {
        position: static;
    }

    .site-header-1 nav.site-mobile-menu {
        margin-top: -59px;
    }

    .site-header-1 .stuck nav.site-mobile-menu {
        margin-top: -57px;
    }

    nav.site-mobile-menu > ul {
        top: 57px;
    }

    header.site-header-1 {
        min-height: 80px;
    }

    .site-heade .btn-mobile-menu {
        margin: 19px 0;
    }

    #logo {
        margin-top: 16px;
    }

    .site-header-2.site-header .btn-mobile-menu {
        margin-bottom: 0px;
    }

    .site-header-2.site-header nav.site-mobile-menu > ul {
        top: 76px;
    }

    .site-header-2 .main-bar .header-buttons {
        margin-right: 20px;
        margin-top: 30px;
    }

    .site-header-2 .search-bar,
    .site-header-2 .header-contact ul li.border-line {
        display: none;
    }

    .site-header-2 .main-bar {
        padding-bottom: 0;
    }

    .site-header-2 .stuck .main-bar {
        display: block;
    }

    .site-header-2 .stuck .sub-header {
        display: none;
    }

    header.site-header-3 #logo {
        top: 15px;
    }

    .latest-projects .latest-projects-intro {
        padding: 15px 25px 9px;
    }

    .testimonials-slider-wrapper {
        margin-top: 40px;
    }

    .service-box {
        margin: 30px 0px 0px;
    }

    .cal-to-action span {
        display: block;
        margin-bottom: 20px;
        line-height: 28px;
        margin-right: 0px;
    }

    .pricing-table-2 .plan-price span {
        right: -40px;
    }

    .projects-grid-5 .project-item {
        width: 25%;
    }

    .blog-list .post-media {
        width: 300px;
    }

    .blog-list .post-content {
        padding-left: 320px;
    }

    .project-info h2 {
        margin-bottom: 25px;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .projects-grid .project-item {
        width: 33.33333333%;
    }
}

@media only screen and (max-width: 767px) {
    .feature-box,
    .about-box {
        margin-top: 40px;
    }

    .about-text-intro h2,
    .service-text-intro h2,
    .intro-text h2 {
        font-size: 35px;
    }

    .latest-projects .latest-projects-wrapper,
    .latest-projects .latest-projects-intro {
        float: none;
        width: 100%;
    }

    .newsletter-element .input-text,
    .newsletter-element .newsletter-submit {
        margin-top: 20px;
    }

    .projects-grid-5 .project-item {
        width: 50%;
    }

    .about-history {
        margin-top: 35px;
    }

    .pricing-item {
        margin-bottom: 20px;
    }

    .pricing-table-2 {
        width: 100%;
        margin: 10px 0px !important;
    }

    .blog-list .post-media {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }

    .blog-list .post-content {
        padding-left: 0px;
    }

    .post-metadata {
        margin: 10px 0px 10px;
    }

    footer .main-footer .widget-title {
        margin-bottom: 15px;
        margin-top: 30px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .item.margin_15 {
        margin: 15px 0px 0px;
    }

    .projects-grid .project-item {
        width: 50%;
    }
}

@media only screen and (max-width: 479px) {
    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    h4 {
        font-size: 14.5px;
    }

    h5 {
        font-size: 13px;
    }

    h6 {
        font-size: 11px;
    }

    h2.box-title {
        font-size: 18px;
    }

    .box-intro-video .btn-intro-video {
        font-size: 18px;
        width: 45px;
        height: 45px;
        line-height: 45px;
    }

    .btn-service-intro .btn {
        margin: 5px 0px;
        min-width: 140px;
    }

    .projects-grid .project-item {
        width: 100%;
    }

    .latest-blog-post-details p.title {
        font-size: 16px;
        font-weight: 500;
    }

    #filter {
        padding: 0px;
    }

    #filter li {
        display: inline-block;
        margin-left: 11px;
        margin-right: 11px;
    }

    .project-grid-2 #filter,
    .project-page #filter {
        margin-bottom: 20px;
        padding-left: 0;
        text-align: center;
    }

    .project-grid-2 #filter li,
    .project-page #filter li {
        margin-left: 11px;
        margin-right: 11px;
    }

    .about-text-intro,
    .service-text-intro,
    .intro-text {
        padding: 0px 0px 34px;
    }

    .about-text-intro h2,
    .service-text-intro h2,
    .intro-text h2 {
        font-size: 25px;
        line-height: 1.2;
        margin-bottom: 15px;
    }

    .about-text-intro p,
    .service-text-intro p,
    .intro-text p {
        font-size: 16px;
        line-height: 28px;
    }

    .col-one-third {
        width: 100%;
    }

    .col-one-third.margin-one-third {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .latest-blog-post-img {
        float: none;
        max-width: inherit;
    }

    .latest-blog-post-details {
        padding-left: 0px;
        margin-top: 15px;
    }

    .item.margin_15 {
        margin: 15px 0px 0px;
    }

    footer.footer-2 .footer-contact ul li span {
        display: none;
    }

    .quick_newsletter .newsletter-element .newsletter-submit {
        width: 100%;
    }

    .projects-grid-5 .project-item {
        width: 100%;
    }
}

@media only screen and (max-width: 319px) {
    h1 {
        font-size: 18px;
    }

    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }

    h4 {
        font-size: 12.5px;
    }

    h5 {
        font-size: 12px;
    }

    h6 {
        font-size: 10px;
    }
}
